<template>
  <div>

    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Código Postal')"
        >
          <b-input-group
            v-if="view===false"
          >
            <b-form-input
              v-model="localization.codigoPostal"
              v-mask="tokenMaskCodigoPostal"
              autocomplete="off"
              @keyup.enter="getFillLocalizationByCodePostal"
            />
            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                size="sm"
                @click.stop.prevent="getFillLocalizationByCodePostal"
              >
                {{ $t('Obter morada e mapa') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.codigoPostal || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Comunidade / Cidade Autónoma')"
        >
          <v-select
            v-if="view===false"
            v-model="localization.comunidade"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="comunidades"
            append-to-body
            :calculate-position="withPopper"
            :loading="loader.comunidade"
            label="desc"
            item-text="desc"
            item-value="id"
            @input="getProvinciasByComunidade"
            @change="getProvinciasByComunidade"
          >
            <template #option="{ desc }">
              {{ desc }}
            </template>
            <div slot="no-options">
              {{ $t('Nenhuma comunidade / cidade autónoma') }}
            </div>
          </v-select>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ renderObjToTxt(localization.comunidade, 'desc') }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Província')"
        >
          <v-select
            v-if="view===false"
            v-model="localization.provincia"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="provincias"
            append-to-body
            :calculate-position="withPopper"
            :loading="loader.provincia"
            label="desc"
            item-text="desc"
            item-value="id"
            @input="getComarcaByProvincia"
            @change="getComarcaByProvincia"
          >
            <template #option="{ desc }">
              {{ desc }}
            </template>
            <div
              v-if="localization.comunidade"
              slot="no-options"
            >
              {{ $t('Nenhuma província') }}
            </div>
            <div
              v-if="!localization.comunidade"
              slot="no-options"
            >
              {{ $t('Escolha uma comunidade / cidade autónoma') }}
            </div>
          </v-select>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ renderObjToTxt(localization.provincia, 'desc') }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Comarca')"
        >
          <v-select
            v-if="view===false"
            v-model="localization.comarca"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="comarcas"
            append-to-body
            :calculate-position="withPopper"
            :loading="loader.comarca"
            label="desc"
            item-text="desc"
            item-value="id"
            @input="getMunicipiosByComarca"
            @change="getMunicipiosByComarca"
          >
            <template #option="{ desc }">
              {{ desc }}
            </template>
            <div
              v-if="localization.provincia"
              slot="no-options"
            >
              {{ $t('Nenhuma comarca') }}
            </div>
            <div
              v-if="!localization.provincia"
              slot="no-options"
            >
              {{ $t('Escolha uma província') }}
            </div>
          </v-select>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ renderObjToTxt(localization.comarca, 'desc') }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Município')"
        >
          <v-select
            v-if="view===false"
            v-model="localization.municipio"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="municipios"
            append-to-body
            :calculate-position="withPopper"
            :loading="loader.municipio"
            label="desc"
            item-text="desc"
            item-value="id"
            @input="getZonasByMunicipio"
            @change="getZonasByMunicipio"
          >
            <template #option="{ desc }">
              {{ desc }}
            </template>
            <div
              v-if="localization.comarca"
              slot="no-options"
            >
              {{ $t('Nenhum município') }}
            </div>
            <div
              v-if="!localization.comarca"
              slot="no-options"
            >
              {{ $t('Escolha uma comarca') }}
            </div>
          </v-select>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ renderObjToTxt(localization.municipio, 'desc') }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Zona')"
        >
          <v-select
            v-if="view===false"
            v-model="localization.zona"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="zonas"
            append-to-body
            :calculate-position="withPopper"
            :loading="loader.zona"
            label="desc"
            item-text="desc"
            item-value="id"
          >
            <template #option="{ desc }">
              {{ desc }}
            </template>
            <div
              v-if="localization.municipio"
              slot="no-options"
            >
              {{ $t('Nenhuma zona') }}
            </div>
            <div
              v-if="!localization.municipio"
              slot="no-options"
            >
              {{ $t('Escolha um município') }}
            </div>
          </v-select>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ renderObjToTxt(localization.municipio, 'desc') }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Rua')"
        >
          <b-form-input
            v-if="view===false"
            v-model="localization.ruaManual"
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.ruaManual || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group
          :label="$t('Nº de porta')"
        >
          <b-form-input
            v-if="view===false"
            v-model="localization.numeroPorta"
            v-mask="['##########']"
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.numeroPorta || '-----' }}
          </p>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          :label="$t('Andar')"
        >
          <v-select
            v-if="view===false"
            v-model="localization.andar"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="floors"
            append-to-body
            :calculate-position="withPopper"
            :loading="loader.andar"
            label="desc"
            item-text="desc"
            item-value="id"
          >
            <template #option="{ desc }">
              {{ desc }}
            </template>
            <div slot="no-options">
              {{ $t('Sem resultados') }}
            </div>
          </v-select>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ renderObjToTxt(localization.andar, 'desc') }}
          </p>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          :label="$t('Fração')"
        >
          <b-form-input
            v-if="view===false"
            v-model="localization.fracao"
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.fracao || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Ajuda de morada')"
        >
          <b-form-input
            v-if="view===false"
            v-model="localization.ajudaMorada"
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.ajudaMorada || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Nome de edifício')"
        >
          <b-form-input
            v-if="view===false"
            v-model="localization.nomeEdificio"
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.nomeEdificio || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { createPopper } from '@popperjs/core'
import vSelect from 'vue-select'
import { mask } from 'vue-the-mask'
import { onUnmounted } from '@vue/composition-api'
import localizationModule from '@store-modules/localization/es'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { showMsgRequest } from '@core-custom/mixins/geral'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
    mask,
  },
  mixins: [showMsgRequest],
  props: {
    fillMapsLatLong: {
      type: Function,
      default: () => {},
    },
    view: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loader: {
        comunidade: false,
        provincia: false,
        comarca: false,
        municipio: false,
        zona: false,
        rua: false,
        andar: false,
      },
      localization: {
        codigoPostal: null,
        comunidade: null,
        provincia: null,
        comarca: null,
        municipio: null,
        zona: null,
        numeroPorta: null,
        andar: null,
        fracao: null,
        ajudaMorada: null,
        nomeEdificio: null,
        ruaManual: null,
        coordenadas: null,
      },
      tokenMaskCodigoPostal: {
        mask: 'XXXXX',
        tokens: {
          X: {
            pattern: /[0-9]/,
            transform(v) {
              return v.toLocaleUpperCase()
            },
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('localization', ['comunidades', 'provincias', 'comarcas', 'municipios', 'zonas', 'floors']),
  },
  async created() {
    try {
      this.loader.comunidade = true
      await store.dispatch('localization/getAllComunidades').then(() => {
        this.loader.comunidade = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
        this.loader.comunidade = false
      })

      this.loader.andar = true
      await store.dispatch('localization/getAllFloors').then(() => {
        this.loader.andar = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
        this.loader.andar = false
      })
    } catch (err) {
      //
    }
  },
  methods: {
    async getProvinciasByComunidade() {
      const self = this

      this.$store.commit('localization/setProvincias', [])
      this.localization.provincia = null

      this.$store.commit('localization/setComarcas', [])
      this.localization.comarca = null

      this.$store.commit('localization/setMunicipios', [])
      this.localization.municipio = null

      this.$store.commit('localization/setZonas', [])
      this.localization.zona = null

      try {
        if (this.localization.comunidade.id) {
          self.loader.provincia = true
          await store.dispatch('localization/getAllProvinciasByComunidade', {
            level1: this.localization.comunidade.id,
          }).then(() => {
            self.loader.provincia = false
          }).catch(error => {
            self.loader.provincia = false
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    async getComarcaByProvincia() {
      const self = this

      this.$store.commit('localization/setComarcas', [])
      this.localization.comarca = null

      this.$store.commit('localization/setMunicipios', [])
      this.localization.municipio = null

      this.$store.commit('localization/setZonas', [])
      this.localization.zona = null

      try {
        if (this.localization.comunidade.id && this.localization.provincia.id) {
          self.loader.comarca = true
          await store.dispatch('localization/getAllComarcasByProvincia', {
            level1: this.localization.comunidade.id,
            level2: this.localization.provincia.id,
          }).then(() => {
            self.loader.comarca = false
          }).catch(error => {
            self.loader.comarca = false
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    async getMunicipiosByComarca() {
      const self = this

      this.$store.commit('localization/setMunicipios', [])
      this.localization.municipio = null

      this.$store.commit('localization/setZonas', [])
      this.localization.zona = null

      try {
        if (this.localization.comunidade.id && this.localization.provincia.id && this.localization.comarca.id) {
          self.loader.municipio = true
          await store.dispatch('localization/getAllMunicipiosByComarca', {
            level1: this.localization.comunidade.id,
            level2: this.localization.provincia.id,
            level3: this.localization.comarca.id,
          }).then(() => {
            self.loader.municipio = false
          }).catch(error => {
            self.loader.municipio = false
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    async getZonasByMunicipio() {
      const self = this

      this.$store.commit('localization/setZonas', [])
      this.localization.zona = null

      try {
        if (this.localization.comunidade.id && this.localization.provincia.id && this.localization.comarca.id && this.localization.municipio.id) {
          self.loader.zona = true
          await store.dispatch('localization/getAllZonasByMunicipio', {
            level1: this.localization.comunidade.id,
            level2: this.localization.provincia.id,
            level3: this.localization.comarca.id,
            level4: this.localization.municipio.id,
          }).then(() => {
            self.loader.zona = false
          }).catch(error => {
            self.loader.zona = false
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    async getFillLocalizationByCodePostal() {
      const self = this

      if (this.localization.codigoPostal && (/^\d{5}$/.test(this.localization.codigoPostal))) {
        this.localization.comunidade = null

        this.$store.commit('localization/setProvincias', [])
        this.localization.provincia = null

        this.$store.commit('localization/setComarcas', [])
        this.localization.comarca = null

        this.$store.commit('localization/setMunicipios', [])
        this.localization.municipio = null

        this.$store.commit('localization/setZonas', [])
        this.localization.zona = null

        this.localization.coordenadas = null

        try {
          self.$root.$emit('app::loading', true)
          await store.dispatch('localization/fillLocalizationByCodePostal', {
            codigoPostal: this.localization.codigoPostal,
          }).then(res => {
            self.$root.$emit('app::loading', false)

            this.localization.comunidade = this.comunidades.find(o => o.id === res.comunidade)
            this.localization.provincia = this.provincias.find(o => o.id === res.provincia)
            this.localization.comarca = this.comarcas.find(o => o.id === res.comarca)
            this.localization.municipio = this.municipios.find(o => o.id === res.municipio)
            this.localization.zona = this.zonas.find(o => o.id === res.zona)
            this.localization.coordenadas = res.coordenadas || null

            this.fillMapsLatLong()
          }).catch(error => {
            self.$root.$emit('app::loading', false)
            this.showMsgErrorRequest(error)
          })
        } catch (err) {
        //
        }
      } else {
        this.showMsgErrorRequest({ message: this.$t('Preencha um código postal válido') })
      }
    },
    getNameSearchGoogleMaps() {
      let txtNameSearchGoogleMaps = ''

      if (this.localization.coordenadas !== null) {
        txtNameSearchGoogleMaps = this.localization.coordenadas
      } else {
        if (this.localization.codigoPostal) {
          txtNameSearchGoogleMaps += this.localization.codigoPostal
        }

        try {
          if (this.localization.comunidade.desc) {
            txtNameSearchGoogleMaps += ((txtNameSearchGoogleMaps) ? ', ' : '')
            txtNameSearchGoogleMaps += this.localization.comunidade.desc
          }
        } catch (err) {
        //
        }

        try {
          if (this.localization.provincia.desc) {
            txtNameSearchGoogleMaps += ((txtNameSearchGoogleMaps) ? ', ' : '')
            txtNameSearchGoogleMaps += this.localization.provincia.desc
          }
        } catch (err) {
        //
        }
      }

      return txtNameSearchGoogleMaps
    },
    withPopper(dropdownList, component, { width }) {
      const oDropdownList = dropdownList
      oDropdownList.style.width = width
      oDropdownList.style.maxHeight = '175px'
      oDropdownList.style.zIndex = '9999'

      const popper = createPopper(component.$refs.toggle, oDropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }],
      })

      return () => popper.destroy()
    },
    async clearForm() {
      try {
        this.localization.codigoPostal = null
        this.localization.comunidade = null
        this.localization.provincia = null
        this.localization.comarca = null
        this.localization.municipio = null
        this.localization.zona = null
        this.localization.numeroPorta = null
        this.localization.andar = null
        this.localization.fracao = null
        this.localization.ajudaMorada = null
        this.localization.nomeEdificio = null
        this.localization.ruaManual = null

        store.commit('localization/setProvincias', [])
        store.commit('localization/setComarcas', [])
        store.commit('localization/setMunicipios', [])
        store.commit('localization/setZonas', [])
      } catch (err) {
        //
      }
    },
    async getDataSaveForm() {
      return new Promise(resolve => {
        let comunidadeID = ''
        let provinciaID = ''
        let comarcaID = ''
        let municipioID = ''
        let zonaID = ''
        let andarID = ''

        if ((this.localization.comunidade !== undefined) && (this.localization.comunidade !== null)) {
          if ('id' in this.localization.comunidade) {
            comunidadeID = this.localization.comunidade.id
          }
        }

        if ((this.localization.provincia !== undefined) && (this.localization.provincia !== null)) {
          if ('id' in this.localization.provincia) {
            provinciaID = this.localization.provincia.id
          }
        }

        if ((this.localization.comarca !== undefined) && (this.localization.comarca !== null)) {
          if ('id' in this.localization.comarca) {
            comarcaID = this.localization.comarca.id
          }
        }

        if ((this.localization.municipio !== undefined) && (this.localization.municipio !== null)) {
          if ('id' in this.localization.municipio) {
            municipioID = this.localization.municipio.id
          }
        }

        if ((this.localization.zona !== undefined) && (this.localization.zona !== null)) {
          if ('id' in this.localization.zona) {
            zonaID = this.localization.zona.id
          }
        }

        if ((this.localization.andar !== undefined) && (this.localization.andar !== null)) {
          if ('id' in this.localization.andar) {
            andarID = this.localization.andar.id
          }
        }

        resolve({
          codigoPostal: this.localization.codigoPostal,
          comunidade: comunidadeID,
          provincia: provinciaID,
          comarca: comarcaID,
          municipio: municipioID,
          zona: zonaID,
          numeroPorta: this.localization.numeroPorta,
          andar: andarID,
          fracao: this.localization.fracao,
          ajudaMorada: this.localization.ajudaMorada,
          nomeEdificio: this.localization.nomeEdificio,
          ruaManual: this.localization.ruaManual,
        })
      })
    },
    async loadDataForm(payload) {
      try {
        this.localization.codigoPostal = payload.codigoPostal || null

        const newItemComunidade = this.comunidades.find(o => Number(o.id) === Number(payload.comunidade || 0))
        if (newItemComunidade !== undefined) {
          this.localization.comunidade = newItemComunidade
          await this.getProvinciasByComunidade(newItemComunidade)
        }

        const newItemProvincia = this.provincias.find(o => Number(o.id) === Number(payload.provincia || 0))
        if (newItemProvincia !== undefined) {
          this.localization.provincia = newItemProvincia
          await this.getComarcaByProvincia(newItemProvincia)
        }

        const newItemComarca = this.comarcas.find(o => Number(o.id) === Number(payload.comarca || 0))
        if (newItemComarca !== undefined) {
          this.localization.comarca = newItemComarca
          await this.getMunicipiosByComarca(newItemComarca)
        }

        const newItemMunicipio = this.municipios.find(o => Number(o.id) === Number(payload.municipio || 0))
        if (newItemMunicipio !== undefined) {
          this.localization.municipio = newItemMunicipio
          await this.getZonasByMunicipio(newItemMunicipio)
        }

        const newItemZona = this.zonas.find(o => Number(o.id) === Number(payload.zona || 0))
        if (newItemZona !== undefined) {
          this.localization.zona = newItemZona
        }

        this.localization.numeroPorta = payload.numeroPorta || null
        this.localization.andar = this.floors.find(o => Number(o.id) === Number(payload.andar || 0))
        this.localization.fracao = payload.fracao || null
        this.localization.ajudaMorada = payload.ajudaMorada || null
        this.localization.nomeEdificio = payload.nomeEdificio || null
        this.localization.ruaManual = payload.ruaManual || null
      } catch (err) {
        //
      }
    },
    renderObjToTxt(value, field) {
      let txt = value
      if ((value !== null) && (value !== undefined) && (field in value)) {
        txt = value[field]
      }

      return txt || '-----'
    },
  },
  setup() {
    const LOCALIZATION_MODULE_NAME = 'localization'

    if (!store.hasModule(LOCALIZATION_MODULE_NAME)) {
      store.registerModule(LOCALIZATION_MODULE_NAME, localizationModule)

      onUnmounted(() => {
        if (store.hasModule(LOCALIZATION_MODULE_NAME)) store.unregisterModule(LOCALIZATION_MODULE_NAME)
      })
    }

    return {
    }
  },
}
</script>
